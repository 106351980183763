import React from 'react'
import Layout from '../components/layout'
import AwardHero from '../components/awardHero'
import Machinery from '../components/sections/machinery'
import PageHeader from '../components/pageHeader'
import SEO from '../components/seo'

export default function FacilitiesPage() {
  return (
    <Layout>
      <SEO title="Live the Dream | Our Facilities "/>
      <PageHeader
        title="Our Facilities"
        subTitle="Our skilled craftspeople, sound and sprawling infrastructure plays a crucial role in our success. We use the latest technology and equipment to deliver exceptional and efficient services to our clients."
      />
      <AwardHero />
      <div className="my-16 container mx-auto">
        <Machinery />
      </div>
      
    </Layout>
  )
}
