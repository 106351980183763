import React from 'react'
import PageHeader from '../pageHeader'
import { useStaticQuery, graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

export const query = graphql`
  {
    facilities: allStrapiFacility {
        nodes {
            name
            description
            image {
                localFile {
                    childImageSharp {
                        gatsbyImageData
                    }
                }
            }
        }
    }
  }
`
export default function Machinery() {
    const data = useStaticQuery(query)
    const facilities = data.facilities.nodes
    return (
        <div>
            <PageHeader title=" State of the Art Machinery"/>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 py-4">
                {facilities.map((facility, i) => {
                    return (
                        <div className={'flex flex-col items-center justify-between w-full gap-2'}>
                            <GatsbyImage 
                                image={getImage(facility?.image?.localFile)}
                                objectFit="cover"
                                class='w-full h-64 border border-primary'
                            />
                            <h2 class="text-xl md:text-3xl 2xl:text-4xl font-playFair group-hover:text-primary">{facility.name}</h2>
                            <p>{facility.description}</p>
                        </div>
                    )
                })}
            </div>
        </div>

    )
}
